<template>
  <div class="">
    <el-row :gutter="12">
      <el-col :span="16">
        <content-block :title="$store.getters.currentCompanyName" class="companyInfoTop">
          <div class="companyInfoContainer">
            <div class="companyInfo">
              <el-image
                  style='width:40px;height:40px;'
                  :src="companyUrl">
                </el-image><span>供应商</span>
            </div>
          </div>
        </content-block>
        <content-block title="业务待办">
          <div class="content_content todoContainer">
            <ul style="width:100%;display:flex;">
              <li v-for="todo in toDoLists" :key="todo.id" @click="handleView(todo)" >
                 <el-image
                  style='width:64px;height:64px;'
                  :src="todo.url"
                  referrer-policy
                >
                </el-image>
                <div class="content">
                  <div class="content_top">
                    {{todo.title}}
                  </div>
                  <div class="content_bottom">
                    {{todo.quantity ? todo.quantity : ''}}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </content-block>
      </el-col>
      <el-col :span="8">
        <content-block title="平台通知">
          <div slot="title" class="content_title">
            平台通知
            <div class="paginationContianer">
              <el-pagination
                small
                layout="prev, pager, next"
                @current-change="handleCurrentChange"
                :current-page="pagination.currentPage"
                :pageSize="pagination.pageSize"
                :total="pagination.total">
              </el-pagination>
            </div>
          </div>
          <div class="content_content notificationContainer">
            <ul>
              <li v-for="notification in platformNotificationLists" :key="notification.id" @click="handleDetail(notification)">
                <div class="ad_content">
                  <span>{{notification.typeName}}</span>
                  <span class="ad_title" :title="notification.title">{{notification.title}}</span>
                </div>
                <time>{{notification.releaseTime}}</time>
              </li>
            </ul>
          </div>
        </content-block>
      </el-col>
      <el-col :span="24" style="margin-top:20px;">
        <content-block title="常用功能">
          <div class="content_content functionContainer">
            <dl v-for="fun in functionLists" :key="fun.label" @click="goToPage(fun)">
              <dt>
                <el-image
                  style='width:20px;height:20px;'
                  referrer-policy
                  :src="fun.url">
                </el-image>
              </dt>
              <dd>{{fun.label}}</dd>
            </dl>
          </div>
        </content-block>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import {getAnnouncementLists, purchaseStatistics} from '@/api/userCenter';
import 'vue-happy-scroll/docs/happy-scroll.css';
import * as Moment from 'dayjs';
export default {
  name: 'USER_INFO',
  methods: {
    handleDetail (info) {
      this.$router.push({
        name: 'DetailAd',
        query: {
          id: info.id,
        },
      });
    },
    handleCurrentChange (val) {
      this.pagination.currentPage = val;
      this.getAnnouncementLists();
    },
    getAnnouncementLists () {
      getAnnouncementLists({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        status: 'PUBLISHED',
        orderBy: [
          {
            orderByProperty: 'lastUpdateTime',
            orderByType: 'DESC',
          },
        ],
      }).then(res =>{
        this.platformNotificationLists = res.body.list.map(item=>{
          return {
            ...item,
            releaseTime: Moment(item.releaseTime).format('MM-DD HH:mm'),
          };
        });
        this.pagination.total = res.body.total;
      });
    },
    goToPage (fun) {
      if (fun.name) {
        this.$router.push(
          {
            name: fun.name,
          },
        );
      }
    },
    handleView (toDo) {
      let routeName = toDo.routeName;
      if (routeName) {
        this.$router.push(
          {
            name: routeName,
            query: {
              status: routeName === 'PickUpOrders' ? 0 : ['SaleContract', 'Service'].includes(routeName) ? 6 : 1,
            },
          },
        );
      }
    },
    getPurchaseStatistics () {
      purchaseStatistics({supId: this.$store.state.User.userInfo.supplierId}).then(res => {
        let info = res.body;
        let currentLists = this.toDoLists;
        currentLists[0].quantity = info.contractQuantity || 0;
        currentLists[1].quantity = info.orderQuantity || 0;
        currentLists[2].quantity = info.afterTBCQuantity || 0;
        currentLists[3].quantity = info.afterTBRQuantity || 0;
        currentLists[4].quantity = info.reconciliationQuantity || 0;
        this.toDoLists = currentLists;
      });
    },
  },
  watch: {
    '$store.state.User.currentSupplierId': {
      handler () {
        this.getAnnouncementLists();
        this.getPurchaseStatistics();
      },
      immediate: true,
      deep: true,
    },
  },
  created () {
    this.$store.dispatch('changeDefaultActive', this.$route.name);
    this.getAnnouncementLists();
    this.getPurchaseStatistics();
  },
  data () {
    return {
      pagination: {
        currentPage: 1,
        pageSize: 7,
        total: 0,
      },
      companyUrl: require('@/assets/imgs/userCenter/suppler.png'),
      platformNotificationLists: [],
      toDoLists: [
        {
          routeName: 'SaleContract',
          title: '合同待审核',
          url: require('@/assets/imgs/userCenter/sicon_01.png'),
          quantity: 0,
          id: 1,
        },
        {
          routeName: 'PickUpOrders',
          title: '订单待发货',
          url: require('@/assets/imgs/userCenter/sicon_02.png'),
          quantity: 0,
          id: 2,
        },
        {
          routeName: 'Service',
          title: '售后待审核',
          url: require('@/assets/imgs/userCenter/sicon_03.png'),
          quantity: 0,
          id: 3,
        },
        {
          routeName: 'ServiceReturn',
          title: '售后待签收',
          url: require('@/assets/imgs/userCenter/sicon_04.png'),
          quantity: 0,
          id: 4,
        },
        {
          routeName: 'AccountStatement',
          title: '对账单待确认',
          url: require('@/assets/imgs/userCenter/sicon_05.png'),
          quantity: 0,
          id: 5,
        },
      ],
      supplierName: '',
      functionLists: [
        {
          label: '销售合同',
          url: require('@/assets/imgs/userCenter/function_01.png'),
          name: 'SaleContract',
        },
        {
          label: '提货订单',
          url: require('@/assets/imgs/userCenter/function_02.png'),
          name: 'PickUpOrders',
        },
        {
          label: '商品管理',
          url: require('@/assets/imgs/userCenter/function_03.png'),
          name: 'Product',
        },
        {
          label: '出库记录',
          url: require('@/assets/imgs/userCenter/function_04.png'),
          name: 'DeliveryRecord',
        },
        {
          label: '退货记录',
          url: require('@/assets/imgs/userCenter/function_05.png'),
          name: 'ReturnRecord',
        },
        // {
        //   label: '退货统计',
        //   url: require('@/assets/imgs/userCenter/function_06.png'),
        //   name: 'ServiceReturn',
        // },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
/deep/ .content_content{
  border: 1px solid rgba(215, 215, 215, 1);
  min-height: 60px;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 5px;
  line-height: 30px;
  i{
    font-size: 20px;
    line-height: 20px;
    &.el-icon-s-home {
      margin-right:5px;
    }
  }
}
/deep/ .notificationContainer.content_content {
  ul {
    list-style: none;
    height: 348px;
    li{
      height:40px;
      line-height: 38px;
      display:flex;
      cursor: pointer;
      background: #FFFFFF;
      font-size: 14px;
      margin-bottom: 6px;
      padding: 0 10px;
      border-radius: 4px;
      border: 1px solid rgba(113, 121, 141, 0.08);
      &:hover{
        box-shadow: 0px 2px 4px 0px rgba(36, 40, 50, 0.1);
      }
      div.ad_content{
        flex: 1;
        color: rgba(36, 40, 50, 0.6);
        white-space:nowrap;
        overflow:hidden;
        text-overflow:ellipsis;
        span:nth-child(1) {
          background: rgba(15, 137, 255, 0.6);
          border-radius: 2px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          padding: 2px 5px;
          margin-right:5px;
        }
      }
      time{
        margin-left: 10px;
        color: rgba(36, 40, 50, 0.4);
      }
    }
  }
}

.todoContainer{
  display: flex;
  background: #FAFDFF;
  box-shadow: 0px 4px 6px 0px rgba(36, 40, 50, 0.08);
  border-radius: 4px;
  ul{
    flex: 1;
    width: 100%;
    display: flex;
    flex-wrap: wrap; /* 允许换行 */
    // justify-content: space-between;
    li{
      display: flex;
      width: 33%;
      margin-right: 0.5%;
      box-sizing: border-box;;
      list-style: none;
      cursor: pointer;
      text-align: center;
      height: 84px;
      background-image: url("~@/assets/imgs/userCenter/toDoBg.png");
      background-repeat: no-repeat;
      background-size:100% 100%;
      background-position: center center;
      margin-bottom: 10px;
      padding: 10px;
      &:nth-child(3n+3){
        margin-right: 0;
      }
      .el-image{
        width:64px;
        height:64px;
        margin-right:20px;
      }
      .content{
        flex: 1;
        display: flex;
        flex-direction: column;
        text-align: left;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        padding-top: 10px;
        padding-bottom: 10px;
        .content_top{
          height: 20px;
          font-size: 16px;
          line-height: 20px;
        }
        .content_bottom{
          font-size: 28px;
          height: 44px;
          line-height: 44px;
        }
      }
    }
  }
}
.companyInfoContainer {
  width: 400px;
  display: flex;
  .companyInfo{
    padding-left: 20px;
    flex: 1;
    display: flex;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(36, 40, 50, 0.3);
    line-height: 14px;
    span{
      flex: 1;
      line-height:40px;
    }
    .el-image{
      margin-right:10px;
    }
  }
}
/deep/ .companyInfoTop{
  padding: 24px 12px;
  // background: #FFFFFF linear-gradient(360deg, rgba(194, 225, 255, 0.11) 1%, #0C7FED 100%);
  box-shadow: 0px 4px 6px 0px rgba(36, 40, 50, 0.08);
  border-radius: 4px;
}
/deep/ .companyInfoTop .content_title{
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(36, 40, 50, 0.8);
  line-height: 24px;
  font-weight: 500;
}
/deep/ .functionContainer{
  display: flex;
  dl{
    flex: 1;
    display: flex;
    align-items:center;/*垂直居中*/
    justify-content: center;/*水平居中*/
    background: #FFFFFF;
    border-radius: 4px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(36, 40, 50, 0.6);
    height:60px;
    text-align: center;
    padding-top:10px;
    padding-bottom:10px;
    margin-left: 10px;
    border: 1px solid rgba(113, 121, 141, 0.08);
    cursor: pointer;
    dt{
      height: 40px;
      border-radius: 50%;
      width: 60px;
      padding-top: 10px;
      box-sizing: border-box;
    }
    dd{
      flex: 1;
      text-align: left;
    }
    &:hover{
      background: #FFFFFF;
      box-shadow: 0px 2px 4px 0px rgba(36, 40, 50, 0.1);
      border-radius: 4px;
      color: #242832;
      border: 1px solid rgba(113, 121, 141, 0.08);
    }
  }
}
/deep/.content_title{
  &::before{
    width: 5px;
    height: 24px;
    content: '';
    background: #2577e3;
    margin-right: 10px;
  }
  display: flex;
  align-items: center;
  height: 36px;
  margin-bottom: 16px;
  font-size: 14px;
  .paginationContianer{
    flex: 1;
    text-align: right;
    .el-pagination{
      margin-right: 10px;
    }
  }
}
</style>
